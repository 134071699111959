<template>
  <div id="SportShowsSales">
    <card style="grid-column: 1 / span 2">
      <qs-kpi qlik-id="QRNejcq" preserve-on-destroy class="single-kpi" />
    </card>
    <QsCard :qlik-ids="['DZRpytH']" style="grid-column: 3 / span 4" />

    <QsCard :qlik-ids="['vjxsFL']" style="grid-column: 1 / span 3" />
    <QsCard :qlik-ids="['mGJLkmg']" style="grid-column: 4 / span 3" />
  </div>
</template>

<script>
import Card from "@/components/Card";
import QsKpi from "@/components/Qlik/QsKpi";
import QsCard from "@/components/Qlik/QsCard";

export default { components: { Card, QsKpi, QsCard } };
</script>

<style scoped>
#SportShowsSales {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 300px 350px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}

.single-kpi {
  font-size: 28px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
